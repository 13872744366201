import { Vector3, Euler } from "three";

export async function restoreInitialState(camera, mesh) {

    let originalCameraState = {
        position: new Vector3(0, 2.50, 5),
        rotation: new Euler(-0.46, 0, 0,),
        target: new Vector3(0, 0, 0),
        angle: 0,
      };
      
      let originalMeshState = {
          position: new Vector3(0, -1, 0),
          rotation: new Euler(0, -0.6, 0, 'XYZ'),
          scale: new Vector3(1, 1, 1),
        };
      

    //console.log("Restoring initial state...");
    camera.position.copy(originalCameraState.position);
    camera.rotation.copy(originalCameraState.rotation);
    camera.lookAt(originalCameraState.target);

    mesh.position.copy(originalMeshState.position);
    mesh.rotation.copy(originalMeshState.rotation);
    mesh.scale.copy(originalMeshState.scale);
  }

  export async function saveSnapshot(renderer, scene, camera, mesh, originalCameraPosition, originalCameraState, shouldSaveToDevice) {
    try {
        // Restore initial state before taking a snapshot
        await restoreInitialState(camera, mesh);

        // Store the original aspect ratio, width, and height
        const originalWidth = renderer.domElement.width;
        const originalHeight = renderer.domElement.height;
        const originalAspect = camera.aspect;

        // Inside the renderAndCapture function, render the scene with the updated size
        async function renderAndCapture(snapshotIndex) {
            return new Promise((resolve, reject) => {
                function animate() {
                    try {
                        // Render the scene for the current position
                        renderer.render(scene, camera);

                        // Capture the image data after rendering
                        const strMime = "image/jpeg";
                        const imgData = renderer.domElement.toDataURL(strMime);

                        // Save the snapshot only if shouldSaveToDevice is true
                        if (shouldSaveToDevice) {
                            saveFile(imgData, "Harlem Customs " + snapshotIndex + ".jpg");
                        }

                        // Append the snapshot image to the modal with ID 'optionModal'
                const modal = document.getElementById('imageContainer');
                const img = document.createElement('img');
                img.src = imgData;
                //img.style.width = '22.5%'; // Set image display size to 50% of the original 1080x1080 size
                img.classList.add('latest-snapshot');
                if (!shouldSaveToDevice) {
                    modal.appendChild(img);
                }
        
                // Resolve the promise to indicate completion
                resolve();

                    } catch (error) {
                        reject(error);
                    }
                }

                // Request the next animation frame to ensure rendering is complete
                requestAnimationFrame(animate);
            });
        }

    async function takeSnapshotsAndRestoreSize() {

        let originalCameraState = {
            position: new Vector3(0, 2.50, 5),
            rotation: new Euler(-0.46, 0, 0,),
            target: new Vector3(0, 0, 0),
            angle: 0,
          };
          
          let originalMeshState = {
              position: new Vector3(0, -1, 0),
              rotation: new Euler(0, -0.6, 0, 'XYZ'),
              scale: new Vector3(1, 1, 1),
            };

        // Set the rendering size for snapshots
        renderer.setSize(2160, 2160);
        camera.aspect = 1;
        camera.updateProjectionMatrix();

        try {
            // Clear existing snapshots from the modal
            const modal = document.getElementById('optionMenu');
            const existingSnapshots = document.querySelectorAll('.latest-snapshot');
            existingSnapshots.forEach(snapshot => {
                snapshot.remove();
                snapshot = null; // Remove reference to the element
            });

            // Restore camera position to initialCamPos
            camera.position.copy(originalCameraState.position);

// Take snapshots for each custom rotation position
const customAngles = [
    Math.PI / 3,
    Math.PI / 1,
    Math.PI / 0.5,
    -Math.PI / 3,

];

for (let snapshotIndex = 0; snapshotIndex < customAngles.length; snapshotIndex++) {
    let rotationAngle = customAngles[snapshotIndex];

    let distance = 4;
    let height = 3;

    // Adjust camera for snapshots
    if (snapshotIndex === 1) {
        height = 4.5;
        distance = 0;
    } else if (snapshotIndex === 3 || snapshotIndex === 0) {
        distance = 4.5;
        height = 0.5;
    }else if (snapshotIndex === 2) {
        height = 0; 
        distance = 5;
    }

    // Apply different coordinate adjustments for specific snapshots
    let leftOffset = 0;
    let forwardOffset = 0;

    camera.position.set(leftOffset, height, distance + forwardOffset);
    camera.lookAt(mesh.position);

    // Set the custom rotation angle for the current snapshot
    mesh.rotation.y = rotationAngle;

    // Wait for the rendering to complete and then capture the image
    await renderAndCapture(snapshotIndex);

    // Wait for a short time before moving to the next snapshot
    await new Promise(resolve => setTimeout(resolve, 50));
}

            if (mesh.material && mesh.material.map) {
                mesh.material.map.dispose();
                mesh.material.map = null;
            }

            mesh.material = null;

        } catch (e) {
            console.log(e);
            return;
        } finally {           

        // Restore camera and mesh states
    camera.position.copy(new Vector3(0, 2.50, 5));
    camera.rotation.copy(originalCameraState.rotation);
    camera.lookAt(originalCameraState.target);

    mesh.position.copy(new Vector3(0, -0.5, 0));
    mesh.rotation.copy(originalMeshState.rotation);
    mesh.scale.copy(originalMeshState.scale);

                     // Restore the original aspect ratio, width, and height
            renderer.setSize(originalWidth, originalHeight);
            camera.aspect = originalAspect;
            camera.updateProjectionMatrix();

            // Get all existing snapshots (after new ones have been appended)
            const updatedSnapshots = document.querySelectorAll('.latest-snapshot');
            /*
            console.log("Total snapshots in the modal -", updatedSnapshots.length);

                // Log values after taking snapshots and restoring properties
    console.log("Restored Camera Position:", camera.position.clone());
    console.log("Restored Camera Rotation:", camera.rotation.clone());
    console.log("Restored Mesh Position:", mesh.position.clone());
    console.log("Restored Mesh Rotation:", mesh.rotation.clone());
    console.log("Restored Mesh Scale:", mesh.scale.clone());

    console.log("Restored Renderer Size:", renderer.domElement.width, renderer.domElement.height);
    */
        }
    }

    var strDownloadMime = "image/octet-stream";

    async function saveFile(strData, filename) {
       // console.log("Saving file:", filename);
        let link = document.createElement('a');
        if (typeof link.download === 'string') {
            document.body.appendChild(link);
            link.download = filename;
            link.href = strData;
            link.click();
            document.body.removeChild(link);
        } else {
            location.replace(uri);
        }
    }

        // Main function call to take snapshots and restore size
        await takeSnapshotsAndRestoreSize();
    } catch (error) {
        console.error("Error occurred while saving snapshot:", error);
    }
}